import React, { useMemo, useState } from 'react'
import HeadBar from '../Components/HeadBar'
import { Form, Button, Modal, Stepper, TextArea, Popup, SearchBar, CheckList, Image, Swiper } from 'antd-mobile';
import styles from '../Styles/addexchange.module.css'
import api from '../Core/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useOnLoad } from '../Core/onLoad'

function AddExchange(props) {
    const history = useHistory()
    const pid = props.match.params.id;
    const [product, setProduct] = useState({
        imgs: []
    })
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [ulist, setUlist] = useState([])
    const [uid, setUid] = useState('')
    const [uname, setUname] = useState('')

    useOnLoad(() => {
        getProduct();
    })


    const onFinish = () => {
        if(!form.getFieldValue('user_id')){
            Modal.alert({
                content:'请选择用户'
            })
            return;
        }
        if(!form.getFieldValue('remark')){
            form.setFieldValue('remark','')
        }
        console.log(form.getFieldsValue())
        // api.post('score/add', form.getFieldsValue(), history, data => {
        //     Modal.alert({
        //         content: '提交成功',
        //         onConfirm: () => {
        //             history.push('/dashboard')
        //         }
        //     })
        // })
    }

    const goEdit = () => {
        history.push('/addproduct/' + pid)
    }

    const getProduct = () => {
        api.get('product/detail', { id: pid }, history, data => {
            setProduct(data)
            form.setFieldValue('product_id',data.id)
            form.setFieldValue('product_amount',1)
        });
    }

    const getUList = () => {
        api.get('student/search', { keyword: keyword, count: 5 }, history, data => {
            setUlist(data)
            setVisible(true)
        });
    }

    return (
        <>
            <HeadBar back></HeadBar>
            <div className={styles.product}>
                <Swiper className={styles.product_img} autoplay loop>
                    {
                        product.imgs.map((img, index) => (
                            <Swiper.Item key={index}>
                                <div className={styles.product_img}>
                                    <img src={img.url} />
                                </div>
                            </Swiper.Item>
                        ))
                    }
                </Swiper>
                <div className={styles.info}>
                    <div className={styles.name}>{product.name}</div>
                    <div>
                        积分:<span className={styles.score}> {product.score} </span>分
                        价格:<span className={styles.price}> {product.price} </span>元
                        <Button style={{ 'float': 'right' }} color='primary' fill='outline' size='small' onClick={() => goEdit()}>编辑</Button>
                    </div>
                    <div className={styles.desc}>
                        {product.desc}
                    </div>
                </div>
            </div>
            <Form
                name='form'
                form={form}
                style={{ 'textAlign': 'left' }}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' style={{ '--background-color': 'orange', 'color': 'white' }} size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item name='product_id' label='product_id' hidden={true}>
                </Form.Item>
                <Form.Item name='user_id' label='id' hidden={true}>
                    <label >{uname}</label>
                </Form.Item>
                <Form.Item label='姓名' onClick={getUList}>
                    <label >{uname}</label>
                </Form.Item>
                <Form.Item name='product_amount' label='数量' childElementPosition='right'>
                    <Stepper min={1} defaultValue={1} />
                </Form.Item>
                <Form.Item name='remark' label='备注'>
                    <TextArea
                        placeholder='请输入备注'
                        maxLength={100}
                        rows={2}
                        showCount
                    />
                </Form.Item>
            </Form>
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                destroyOnClose
            >
                <div className={styles.searchBarContainer}>
                    <SearchBar
                        placeholder='输入名字搜索'
                        value={keyword}
                        onChange={v => {
                            setKeyword(v)
                        }}
                        onSearch={() => { getUList(); }}
                    />
                </div>
                <div className={styles.checkListContainer}>
                    <CheckList
                        className={styles.myCheckList}
                        defaultValue={uid ? [uid + '|' + uname] : []}
                        onChange={val => {
                            if (val.length > 0) {
                                let id = val[0].split('|')[0]
                                let name = val[0].split('|')[1]
                                setUid(id)
                                setUname(name)
                                form.setFieldValue('user_id',id)
                            }
                            else {
                                setUid('')
                                setUname('')
                                form.setFieldValue('user_id',0)
                            }
                            setVisible(false)
                        }}
                    >
                        {ulist.map(item => (
                            <CheckList.Item key={item.id} value={item.id + '|' + item.name}>
                                {item.name}
                            </CheckList.Item>
                        ))}
                    </CheckList>
                </div>
            </Popup>
        </>
    )
}

export default AddExchange;