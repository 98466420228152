import React, { useMemo, useState } from 'react'
import HeadBar from '../Components/HeadBar'
import { Form, Button, Modal, Stepper, TextArea, Popup, SearchBar, CheckList } from 'antd-mobile';
import styles from '../Styles/addscore.module.css'
import api from '../Core/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useOnLoad } from '../Core/onLoad'

function AddScore() {
    const history = useHistory()
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [ulist, setUlist] = useState([])
    const [uid, setUid] = useState('')
    const [uname, setUname] = useState('')

    useOnLoad(() => {
        form.setFieldValue('amount', 0)
        form.setFieldValue('remark', '')
        getUList();
    })


    const onFinish = () => {
        api.post('score/add', form.getFieldsValue(), history, data => {
            Modal.alert({
                content: '提交成功',
                onConfirm: () => {
                    history.push('/dashboard')
                }
            })
        })
    }

    const getUList = () => {
        api.get('student/search', { keyword: keyword, count: 5 }, history, data => {
            setUlist(data)
            setVisible(true)
        });
    }

    return (
        <>
            <HeadBar back></HeadBar>
            <Form
                name='form'
                form={form}
                style={{ 'textAlign': 'left' }}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' style={{ '--background-color': 'orange', 'color': 'white' }} size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item name='user_id' label='id' hidden={true}>
                    <label >{uname}</label>
                </Form.Item>
                <Form.Item label='姓名' onClick={getUList}>
                    <label >{uname}</label>
                </Form.Item>
                <Form.Item name='amount' label='积分' childElementPosition='right'>
                    <Stepper defaultValue={0} />
                </Form.Item>
                <Form.Item name='remark' label='备注'>
                    <TextArea
                        placeholder='请输入备注'
                        maxLength={100}
                        rows={2}
                        showCount
                    />
                </Form.Item>
            </Form>
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                destroyOnClose
            >
                <div className={styles.searchBarContainer}>
                    <SearchBar
                        placeholder='输入名字搜索'
                        value={keyword}
                        onChange={v => {
                            setKeyword(v)
                        }}
                        onSearch={() => { getUList(); }}
                    />
                </div>
                <div className={styles.checkListContainer}>
                    <CheckList
                        className={styles.myCheckList}
                        defaultValue={uid ? [uid + '|' + uname] : []}
                        onChange={val => {
                            if (val.length > 0) {
                                let id = val[0].split('|')[0]
                                let name = val[0].split('|')[1]
                                form.setFieldValue('user_id', id)
                                setUid(id)
                                setUname(name)
                            }
                            else {
                                setUid('')
                                setUname('')
                            }
                            setVisible(false)
                        }}
                    >
                        {ulist.map(item => (
                            <CheckList.Item key={item.id} value={item.id + '|' + item.name}>
                                {item.name}
                            </CheckList.Item>
                        ))}
                    </CheckList>
                </div>
            </Popup>
        </>
    )
}

export default AddScore;