import React, { FC, useState } from 'react'
import {
    Form,
    Input,
    Button,
    ImageUploader,
    Toast,
    Modal,
    Stepper
} from 'antd-mobile'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import HeadBar from '../Components/HeadBar'
import api from '../Core/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useOnLoad } from '../Core/onLoad'

function AddUser(props) {
    const id = props.match.params.id;
    const history = useHistory()
    const [form] = Form.useForm()
    const [imgs, setImgs] = useState([])

    useOnLoad(() => {
        getUser()
    })

    const onFinish = (value) => {
        api.post('student/edit', form.getFieldsValue(), history, data => {
            Modal.alert({
                content: '提交成功',
                onConfirm: () => {
                    history.push('/dashboard')
                }
            })
        })
    }

    const getUser = () => {
        if (id != undefined) {
            api.get('student/detail', { id: id }, history, data => {
                form.setFieldValue('id', id)
                form.setFieldValue('name', data.name)
                form.setFieldValue('score',data.score)
                form.setFieldValue('img', [data.img])
            });
        }
        else {
            form.setFieldValue('id', 0)
        }
    }

    function uploadImg(file) {
        return new Promise((resolve, reject) => {
            api.upload('media/uploadimg', { dir: 'user' }, [file], history, data => {
                data.map(item => {
                    let obj = {
                        id: item.id,
                        url: item.url
                    };
                    setImgs([...imgs, obj])
                    resolve(obj);
                })
            })
        });
    }
    function delImg(delItem) {
        api.get('media/delete', { id: delItem.id }, history, data => {
            setImgs(prevList => prevList.filter(item => item !== delItem));
        })
    }

    function beforeUpload(file) {
        if (file.size > 1024 * 1024) {
            Toast.show('请选择小于 1M 的图片')
            return null
        }
        return file
    }
    return (
        <>
            <HeadBar back></HeadBar>
            <Form
                name='form'
                style={{ 'textAlign': 'left' }}
                form={form}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' style={{ '--background-color': 'orange', 'color': 'white' }} size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item name='id' hidden={true}>
                    <Input type='hidden' />
                </Form.Item>
                <Form.Item name='name' label='姓名' rules={[{ required: true }]}>
                    <Input placeholder='请输入姓名' />
                </Form.Item>
                <Form.Item name='score' label='积分' childElementPosition='right'>
                    <Stepper defaultValue={0} />
                </Form.Item>
                <Form.Item name='img' label='头像'>
                    <ImageUploader
                        maxCount={1}
                        upload={uploadImg}
                        beforeUpload={beforeUpload}
                        onDelete={delImg}
                    />
                </Form.Item>

            </Form>
        </>
    )
}

export default AddUser;