import { SearchBar, List, Image } from "antd-mobile";
import styles from '../Styles/store.module.css'
import BottomBar from "../Components/BottomBar";
import HeadBar from '../Components/HeadBar'
import { useOnLoad } from '../Core/onLoad'
import { useState } from "react";
import api from "../Core/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Store() {
    const history = useHistory()
    const [keyword, setKeyword] = useState('')
    const [products, setProducts] = useState([])
    useOnLoad(() => {
        getData();
    })

    const getData = () => {
        try {
            // 获取排行榜
            api.get('product/list', { keyword: keyword }, history, data => {
                setProducts(data)
            })
        } catch (error) {
            console.error(error);
        }
    }

    const goExchange = (id) => {
        history.push('/addexchange/' + id)
    }

    return (
        <>
            <HeadBar></HeadBar>
            <div className={styles.search_container}>
                <SearchBar placeholder='请输入内容' showCancelButton onChange={(e) => setKeyword(e)} onSearch={(e) => getData()} onCancel={(e) => getData()} />
            </div>
            <div className={styles.product_list}>
                {products.map(prod => (
                    <div key={prod.id} className={styles.product_list_item} onClick={() => goExchange(prod.id)}>
                        <div className={styles.product_list_item_left}>
                            <Image lazy src={prod.img}></Image>
                        </div>
                        <div className={styles.product_list_item_right}>
                            <div className={styles.name}>{prod.name}</div>
                            <div>兑换积分：<span className={styles.score}>{prod.score}分</span> <span className={styles.price}>（价格：{prod.price}元）</span></div>
                            <div className={styles.desc}>{prod.desc}</div>
                        </div>
                    </div>
                ))}
            </div>
            <BottomBar tab="store"></BottomBar>
        </>
    )
}

export default Store;