import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TabBar } from 'antd-mobile'
import { HistogramOutline, ShopbagOutline, AppstoreOutline } from 'antd-mobile-icons'

function BottomBar(props) {
    const tabs = [
        {
            key: 'home',
            title: '排行榜',
            icon: <HistogramOutline />,
            path: '/'
        },
        {
            key: 'store',
            title: '小卖部',
            icon: <ShopbagOutline />,
            path: '/store'
        },
        {
            key: 'dashboard',
            title: '工作台',
            icon: <AppstoreOutline />,
            path: '/dashboard'
        }
    ]
    const history = useHistory();
    function tabChange(key){
        history.push(tabs.find(a=> a.key == key).path);
    }
    return (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', background: 'white' }}>
            <TabBar activeKey={props.tab} onChange={tabChange}>
                {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    )
}

export default BottomBar;