import { Button, Badge, Space, Swiper, Toast, Image, Grid } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import styles from '../Styles/dashboard.module.css'
import add_user_img from '../Images/add_user.png'
import users_img from '../Images/users.png'
import scores_img from '../Images/scores.png'
import ad_product_img from '../Images/add_product.png'
import password_img from '../Images/password.png'
import BottomBar from '../Components/BottomBar'
import HeadBar from '../Components/HeadBar'
import { useOnLoad } from '../Core/onLoad'
import { useState } from 'react'
import moment from 'moment'

function Dashboard() {
    const [username,setUsername] = useState('')
    const [hello,setHello] = useState('')
    const [day,setDay] = useState('')
    useOnLoad(()=>{
        setHello(getHello())
        setDay(moment().format('YYYY年MM月DD日'))

        var username = sessionStorage.getItem('username')
        if(username!=undefined){
            setUsername(username)
        }
    })

    const getHello =()=>{
        let hour = new Date().getHours()
        if (hour < 6)
            {
                return "夜深了，早点休息";
            }
            else if (hour < 11)
            {
                return "新的一天，早安";
            }
            else if (hour < 14)
            {
                return "休息一下，睡个午觉";
            }
            else if (hour < 16)
            {
                return "金色的下午，元气满满";
            }
            else if (hour < 18)
            {
                return "快结束工作了，奥利给";
            }
            else
            {
                return "星光灿烂，明天也要加油";
            }
    }

    const history = useHistory();
    const navigateTo = (path) => {
        history.push(path)
    }
    return (
        <>
            <HeadBar></HeadBar>
            <div className={styles.info_container}>
                <div className={styles.info}>
                    <div className={styles.info_list}>
                        <p>{username}老师，今天是{day}</p>
                        <p>{hello}</p>
                    </div>
                </div>
            </div>
            <div className={styles.tools} style={{ 'textAlign': 'center', 'color': 'gray' }}>
                <Grid columns={4} gap={24}>
                <Grid.Item onClick={() => navigateTo('/password')}>
                        <Badge>
                            <div className={styles.box}>
                                <Image src={password_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>修改密码</div>
                    </Grid.Item>
                    <Grid.Item onClick={() => navigateTo('/adduser')}>
                        <Badge>
                            <div className={styles.box}>
                                <Image src={add_user_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>新学生</div>
                    </Grid.Item>
                    <Grid.Item onClick={() => navigateTo('/users')}>
                        <Badge>
                            <div className={styles.box} >
                                <Image src={users_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>学生档案</div>
                    </Grid.Item>
                    <Grid.Item onClick={() => navigateTo('/addscore')}>
                        <Badge content={Badge.dot}>
                            <div className={styles.box} >
                                <Image src={scores_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>新积分</div>
                    </Grid.Item>
                    <Grid.Item onClick={() => navigateTo('/scores')}>
                        <Badge>
                            <div className={styles.box} >
                                <Image src={scores_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>积分列表</div>
                    </Grid.Item>
                    <Grid.Item onClick={() => navigateTo('/addproduct')}>
                        <Badge>
                            <div className={styles.box} >
                                <Image src={ad_product_img} fit='cover'></Image>
                            </div>
                        </Badge>
                        <div>新商品</div>
                    </Grid.Item>
                </Grid>
            </div>
            <BottomBar tab="dashboard"></BottomBar>
        </>
    )
}

export default Dashboard;