import React, { FC, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Modal
} from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import HeadBar from '../Components/HeadBar'
import api from '../Core/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function Password() {
    const history = useHistory()
    const [pwd, setPwd] = useState('')
    const [vPwd, setVPwd] = useState(false)
    const [newPwd, setNewPwd] = useState('')
    const [newPwd1, setNewPwd1] = useState('')
    const [vNewPwd, setVNewPwd] = useState(false)
    const onFinish = (value) => {
        if(newPwd != newPwd1){
            Modal.alert({
                content: '两次输入新密码不一致'
            })
            return;
        }

        api.post('user/updatepwd',{
            pwd:pwd,
            new_pwd:newPwd
        },history,data=>{
            Modal.alert({
                content: '修改成功',
                onConfirm:()=>{
                    history.push('/dashboard')
                }
            })
        });
    }
    return (
        <>
            <HeadBar back></HeadBar>
            <Form
                name='form'
                style={{ 'textAlign': 'left' }}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' style={{ '--background-color': 'orange', 'color': 'white' }} size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item
                    label='原密码'
                    name='pwd'
                    rules={[{ required: true }]}
                    extra={
                        <div>
                            {!vPwd ? (
                                <EyeInvisibleOutline onClick={() => setVPwd(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVPwd(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='请输入原密码'
                        clearable
                        type={vPwd ? 'text' : 'password'}
                        onChange={(e) => setPwd(e)}
                    />
                </Form.Item>

                <Form.Item
                    label='新密码'
                    name='new_pwd'
                    rules={[{ required: true }]}
                    extra={
                        <div>
                            {!vNewPwd ? (
                                <EyeInvisibleOutline onClick={() => setVNewPwd(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVNewPwd(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='请输入新密码'
                        clearable
                        type={vNewPwd ? 'text' : 'password'}
                        onChange={(e) => setNewPwd(e)}
                    />
                </Form.Item>

                <Form.Item
                    label='确认密码'
                    name='new_pwd1'
                    rules={[{ required: true }]}
                    extra={
                        <div>
                            {!vNewPwd ? (
                                <EyeInvisibleOutline onClick={() => setVNewPwd(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVNewPwd(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='请输入确认密码'
                        clearable
                        type={vNewPwd ? 'text' : 'password'}
                        onChange={(e) => setNewPwd1(e)}
                    />
                </Form.Item>

            </Form>
        </>
    )
}

export default Password;