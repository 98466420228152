import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Input, Button } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import HeadBar from '../Components/HeadBar'
import api from '../Core/api'

export default () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const history = useHistory();

  const loginHanler = (e) => {
    try {
      // 使用封装的 GET 方法获取数据
      api.post('user/login', form.getFieldsValue(), history, data => {
        sessionStorage.setItem("token", data.token)
        sessionStorage.setItem('username', data.user.username)
        if (window.location.pathname == '/login') {
          history.push('/')
        }
        window.location.reload();
      })
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      <HeadBar></HeadBar>
      <div style={{ 'padding': '20px', 'textAlign': 'left' }}>
        <Form layout='horizontal' style={{ 'borderRadius': '5px', 'overflow': 'hidden' }} form={form}>
          <Form.Item label='用户名' name='user_id'>
            <Input placeholder='请输入用户名' clearable />
          </Form.Item>
          <Form.Item
            label='密码'
            name='pwd'
            extra={
              <div>
                {!visible ? (
                  <EyeInvisibleOutline onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setVisible(false)} />
                )}
              </div>
            }
          >
            <Input
              placeholder='请输入密码'
              clearable
              type={visible ? 'text' : 'password'}
              onEnterPress={loginHanler}
            />
          </Form.Item>
        </Form>
        <Button block type='submit' color='primary' style={{ 'marginTop': '20px' }} size='large' onClick={loginHanler}>
          登录
        </Button>
      </div>
    </>
  )
}