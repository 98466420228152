import React, { useEffect, useState } from 'react';
import { Grid, Image, SearchBar } from 'antd-mobile';
import styles from '../Styles/users.module.css'
import HeadBar from '../Components/HeadBar'
import { useOnLoad } from '../Core/onLoad'
import api from '../Core/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import default_img from '../Images/default.png'

function Users() {
    const history = useHistory()
    const [keyword, setKeyword] = useState('')
    const [users, setUsers] = useState([]);
    useOnLoad(() => {
        getData();
    })

    const getData = function () {
        try {
            // 获取排行榜
            api.get('student/list', { keyword: keyword }, history, data => {
                setUsers(data)
            })
        } catch (error) {
            console.error(error);
        }
    }

    const goDetail = (id)=>{
        history.push('/adduser/' + id)
    }

    return (
        <>
            <HeadBar back></HeadBar>
            <div>
                <div className={styles.search_container}>
                    <SearchBar placeholder='请输入姓名' showCancelButton onChange={(e) => setKeyword(e)} onSearch={(e) => getData()} onClear={v => {
                        setKeyword('');
                        getData();
                    }} />
                </div>
                <div className={styles.list_container}>
                    <Grid columns={3} gap={20}>
                        {
                            users.map(user => (
                                <Grid.Item key={user.id} onClick={()=> goDetail(user.id)}>
                                    <div className={styles.box}>
                                        <Image lazy src={user.img ? user.img : default_img}></Image>
                                        <div className={styles.name}>{user.name}({user.score}分)</div>
                                    </div>
                                </Grid.Item>
                            ))
                        }
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default Users;