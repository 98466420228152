import axios from "axios";
import { Form, Input, Button, Modal } from 'antd-mobile'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const base_url = 'https://qisi-api.mrxx.cc/'
// 封装 GET 方法
const get = async (path, params, history, success) => {
    try {
        var token = sessionStorage.getItem('token')
        let url = base_url + path + '?token=' + token;
        await axios.get(url, { params })
            .then(res => {
                if (res.data.err_code == 0) {
                    success(res.data.data)
                }
                else {
                    throw res.data.msg
                }
            }, error => {
                if (error.response.status == 401) {
                    history.push('/login')
                }
            })
    } catch (error) {
        // 处理错误
        Modal.alert({
            content: error
        })
    }
};

// 封装 POST 方法
const post = async (path, data, history, success) => {
    try {
        var token = sessionStorage.getItem('token')
        let url = base_url + path + '?token=' + token;
        await axios.post(url, data)
            .then(res => {
                if (res.data.err_code == 0) {
                    success(res.data.data)
                }
                else {
                    throw res.data.msg
                }
            }, error => {
                if (error.response.status == 401) {
                    history.push('/login')
                }
            })
    } catch (error) {
        // 处理错误
        Modal.alert({
            content: error
        })
    }
};

const upload = async (path, params, files, history, success) => {
    try {
        var token = sessionStorage.getItem('token')
        let url = base_url + path + '?token=' + token;

        const formData = new FormData();
        // 将params参数添加到formData
        for (let key in params) {
            formData.append(key, params[key]);
        }

        // 将files参数添加到formData
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.err_code == 0) {
                success(res.data.data)
            }
            else {
                throw res.data.msg
            }
        }, error => {
            if (error.response.status == 401) {
                history.push('/login')
            }
        })
    }
    catch (error) {
        Modal.alert({
            content: error
        })
    }
}

export default { get, post, upload }