import React, { useEffect, useState } from 'react';
import { List, Selector, Tag, DatePicker, Button } from 'antd-mobile'
import HeadBar from '../Components/HeadBar'
import styles from '../Styles/scores.module.css'
import { useOnLoad } from '../Core/onLoad'
import api from '../Core/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

function Scores() {
    const options = [
        {
            label: '全部',
            value: 0,
        },
        {
            label: '兑换',
            value: 1,
        },
        {
            label: '奖励',
            value: 2,
        },
    ]
    const history = useHistory()
    const currentMonth = moment();
    const firstDayOfMonth = currentMonth.clone().startOf('month');
    const lastDayOfMonth = currentMonth.clone().endOf('month');
    const [showBegin, setShowBegin] = useState(false)
    const [showEnd, setShowEnd] = useState(false)
    const [begin, setBegin] = useState(firstDayOfMonth.format('YYYY-MM-DD'))
    const [end, setEnd] = useState(lastDayOfMonth.format('YYYY-MM-DD'))
    const [type, setType] = useState(0)
    const [scores, setScores] = useState([]);

    useOnLoad(() => {
        getData();
    }, [begin, end, type])

    const getData = function () {
        try {
            // 获取排行榜
            api.get('score/list', { type: type, begin: begin, end: end }, history, data => {
                setScores(data)
            })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <HeadBar back></HeadBar>
            <div className={styles.filter}>
                <div>
                    <Button onClick={() => { setShowBegin(true) }} style={{ 'background': '#f0f0f0' }} >{begin}</Button><span> - </span>
                    <Button onClick={() => { setShowEnd(true) }} style={{ 'background': '#f0f0f0' }} >{end}</Button>
                </div>
                <Selector
                    options={options}
                    value={[type]}
                    onChange={v => {
                        if (v.length) {
                            setType(v[0])
                        }
                    }}
                    style={{ 'marginTop': '10px' }}
                />
            </div>
            <div>
                <DatePicker
                    title='时间选择'
                    visible={showBegin}
                    value={new Date(begin)}
                    onClose={() => {
                        setShowBegin(false)
                    }}
                    onConfirm={val => {
                        setBegin(moment(val).format('YYYY-MM-DD'));
                    }}
                />
                <DatePicker
                    title='时间选择'
                    visible={showEnd}
                    value={new Date(end)}
                    onClose={() => {
                        setShowEnd(false)
                    }}
                    onConfirm={val => {
                        setEnd(moment(val).format('YYYY-MM-DD'));
                    }}
                />
            </div>
            <div className={styles.list}>
                <List>
                    {
                        scores.map(item => (
                            <List.Item key={item.id} title={item.create_date} description={item.remark}>
                                <label>{item.name}</label>
                                <Tag color={item.score < 0 ? '#ff6430' : '#87d068'} fill='outline' style={{ 'float': 'right' }}>
                                    {item.score}积分
                                </Tag>
                            </List.Item>
                        ))
                    }
                </List>
            </div>
        </>
    )
}

export default Scores;