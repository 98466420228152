import { LeftOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom';
import styles from '../Styles/head.module.css'

function HeadBar(props) {
    const history = useHistory();
    function goBack() {
        history.goBack();
    }
    return (
        <div className={styles.navbar}>
            {props.back ? <LeftOutline style={{ "position": 'absolute', 'left': 15 }} onClick={goBack}></LeftOutline> : null}
            启思2.0</div>
    )
}

export default HeadBar;