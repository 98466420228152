import type { DependencyList, EffectCallback } from "react"
import { useEffect, useRef } from "react"

export function useOnLoad(effect: EffectCallback, deps: DependencyList | undefined) {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      effect();
    }
  }, []);

  useEffect(() => {
    if (mounted.current && deps && deps.length > 0) {
      effect();
    }
  }, deps);
}
