import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import router from './router';
import Login from './Pages/Login';

function App() {
const token = sessionStorage.getItem("token")

  return (
    <div className="App">
      <Router>
        <Switch>
          {
            router.map((item, key) => {
              return (
                <Route key={key} path={item.path} exact render={
                  props => (
                    !item.auth ? (<item.component {...props} />) : (token ? <item.component {...props} /> : <Login/>)
                  )
                } />
              )
            })
          }
        </Switch>
      </Router>
    </div>
  );
}

export default App;
