import React, { FC, useRef, useState } from 'react'
import {
    Form,
    Input,
    Button,
    ImageUploader,
    Toast,
    NumberKeyboard,
    Stepper,
    TextArea,
    Modal
} from 'antd-mobile'
import HeadBar from '../Components/HeadBar'
import api from '../Core/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useOnLoad } from '../Core/onLoad'

function AddProduct(props) {
    const history = useHistory()
    const id = props.match.params.id;
    const [form] = Form.useForm()
    const [imgs, setImgs] = useState([])

    useOnLoad(() => {
        getProduct()
    })

    const onFinish = (value) => {
        api.post('product/edit', form.getFieldsValue(), history, data => {
            Modal.alert({
                content: '提交成功',
                onConfirm: () => {
                    history.push('/dashboard')
                }
            })
        })
    }

    const getProduct = () => {
        if (id != undefined) {
            api.get('product/detail', { id: id }, history, data => {
                form.setFieldValue('id', id)
                form.setFieldValue('name', data.name)
                form.setFieldValue('price', data.price)
                form.setFieldValue('score', data.score)
                form.setFieldValue('desc', data.desc)
                if (data.imgs != undefined && data.imgs.length > 0) {
                    form.setFieldValue('imgs', data.imgs.map(item => {
                        return {
                            id: item.id,
                            url: item.url
                        };
                    }))
                }
            });
        }
        else {
            form.setFieldValue('id', 0)
        }
    }

    function uploadImg(file) {
        return new Promise((resolve, reject) => {
            api.upload('media/uploadimg', { dir: 'product' }, [file], history, data => {
                data.map(item => {
                    let obj = {
                        id: item.id,
                        url: item.url
                    };
                    setImgs([...imgs, obj])
                    resolve(obj);
                })
            })
        });
    }
    function delImg(delItem) {
        api.get('media/delete', { id: delItem.id }, history, data => {
            setImgs(prevList => prevList.filter(item => item !== delItem));
        })
    }

    function beforeUpload(file) {
        if (file.size > 1024 * 1024) {
            Toast.show('请选择小于 1M 的图片')
            return null
        }
        return file
    }
    return (
        <>
            <HeadBar back></HeadBar>
            <Form
                name='form'
                form={form}
                style={{ 'textAlign': 'left' }}
                onFinish={onFinish}
                footer={
                    <Button block type='submit' style={{ '--background-color': 'orange', 'color': 'white' }} size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item name='id' hidden={true}>
                    <Input type='hidden' />
                </Form.Item>
                <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                    <Input placeholder='请输入名称' />
                </Form.Item>

                <Form.Item name='price' label='价格'>
                    <Input type='number' min={0} step='0.01' />
                </Form.Item>
                <Form.Item name='score' label='积分' childElementPosition='right'>
                    <Stepper min={1} defaultValue={1} />
                </Form.Item>
                <Form.Item name='desc' label='描述'>
                    <TextArea
                        placeholder='请输入描述'
                        maxLength={100}
                        rows={2}
                        showCount
                    />
                </Form.Item>
                <Form.Item name='imgs' label='图片'>
                    <ImageUploader
                        maxCount={4}
                        upload={uploadImg}
                        beforeUpload={beforeUpload}
                        onDelete={delImg}
                    />
                </Form.Item>
            </Form>
        </>
    )
}

export default AddProduct;