import { List, Image, Swiper } from "antd-mobile";
import BottomBar from "../Components/BottomBar";
import HeadBar from '../Components/HeadBar'
import styles from '../Styles/home.module.css'
import api from '../Core/api'
import React, { useEffect, useState } from 'react';
import { useOnLoad } from '../Core/onLoad'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import default_img from '../Images/default.png'

function Home() {
    const history = useHistory();
    const [score_list, setScoreList] = useState([]);
    const [product_list, setProductList] = useState([]);
    useOnLoad(() => {
        try {
            // 获取排行榜
            api.get('score/gettop', {}, history, data => {
                if (data.length > 0) {
                    setScoreList(data)
                }
            })
        } catch (error) {
            console.error(error);
        }

        try {
            // 获取排行榜
            api.get('product/gettop', {}, history, data => {
                if (data.length > 0) {
                    setProductList(data)
                }
            })
        } catch (error) {
            console.error(error);
        }
    });

    const goDetail = (id)=>{
        history.push('/addproduct/' + id)
    }

    const colors = ['https://pic.616pic.com/ys_bnew_img/00/48/74/qJyl6rYMEh.jpg',
        'https://img0.baidu.com/it/u=1305706628,607802706&fm=253&fmt=auto&app=138&f=JPEG?w=1000&h=500']

    const items = colors.map((item, index) => (
        <Swiper.Item key={index}>
            <div
                className={styles.ad_item}
            >
                <img src={item}></img>
            </div>
        </Swiper.Item>
    ))
    return (
        <>
            <HeadBar></HeadBar>
            <Swiper autoplay loop>{items}</Swiper>
            <div className={styles.phb}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        {score_list.length > 1 &&
                            <div onClick={()=>{ history.push('/adduser/' + score_list[1].id) }} ><img className={styles.headimg} src={score_list[1].img ? score_list[1].img : default_img} ></img><div className={styles.name}>{score_list[1].name} +{score_list[1].score}</div></div>
                        }
                        <div className={styles.top_item}></div>
                    </div>
                    <div className={styles.item}>
                        {score_list.length > 0 &&
                            <div onClick={()=>{ history.push('/adduser/' + score_list[0].id) }} ><img className={styles.headimg} src={score_list[0].img ? score_list[0].img : default_img}></img><div className={styles.name}>{score_list[0].name} +{score_list[0].score}</div></div>
                        }
                        <div className={styles.top_item}></div>
                    </div>
                    <div className={styles.item}>
                        {score_list.length > 2 &&
                            <div onClick={()=>{ history.push('/adduser/' + score_list[2].id) }} ><img className={styles.headimg} src={score_list[2].img ? score_list[2].img : default_img}></img><div className={styles.name}>{score_list[2].name} +{score_list[2].score}</div></div>
                        }
                        <div className={styles.top_item}></div>
                    </div>
                </div>
            </div>
            <div className={styles.cxb}>
                <List header={<div className={styles.title}>热门兑换</div>}>
                    {product_list.map(prod => (
                        <List.Item
                            key={prod.id}
                            onClick={()=> goDetail(prod.id)}
                            prefix={
                                <Image
                                    src={prod.img ? prod.img : default_img}
                                    fit='cover'
                                    width={60}
                                    height={60}
                                    style={{ borderRadius: 5 }}
                                />
                            }

                            description={<div>积分：{prod.score} 兑换次数：{prod.amount}</div>}
                        >
                            {prod.name}
                        </List.Item>
                    ))}
                </List>
            </div>
            <BottomBar tab="home"></BottomBar>
        </>
    )
}

export default Home;