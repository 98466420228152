import Home from './Pages/Home'
import Store from './Pages/Store'
import Dashboard from './Pages/Dashboard'
import AddUser from './Pages/AddUser'
import Users from './Pages/Users';
import AddScore from './Pages/AddScore'
import Scores from './Pages/Scores'
import AddProduct from './Pages/AddProduct'
import Login from './Pages/Login'
import Password from './Pages/Password';
import AddExchange from './Pages/AddExchange';

const router = [
    {
        path: '/',
        component: Home,
        auth: true
    },
    {
        path: '/login',
        component: Login,
        auth: false
    },
    {
        path: '/password',
        component: Password,
        auth: true
    },
    {
        path: '/store',
        component: Store,
        auth: true
    },
    {
        path: '/dashboard',
        component: Dashboard,
        auth: true
    },
    {
        path: '/adduser',
        component: AddUser,
        auth: true
    },
    {
        path: '/adduser/:id',
        component: AddUser,
        auth: true
    },
    {
        path: '/users',
        component: Users,
        auth: true
    },
    {
        path: '/addscore',
        component: AddScore,
        auth: true
    },
    {
        path: '/scores',
        component: Scores,
        auth: true
    },
    {
        path: '/addproduct',
        component: AddProduct,
        auth: true
    },
    {
        path: '/addproduct/:id',
        component: AddProduct,
        auth: true
    },
    {
        path: '/addexchange/:id',
        component: AddExchange,
        auth: true
    }
];

export default router;